
import { defineComponent } from "vue";
import ArrowBack from "@/assets/svg/arrow-back.svg?inline";
import { Form, Field } from "vee-validate";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import Select from "@/components/atoms/CustomSelect.vue";
import PhotoMock from "@/assets/svg/photo-zaglushka.svg?inline";
import authHeader from "@/services/auth-header";
import Search from "@/assets/svg/search.svg?inline";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import PinSvg from "@/assets/svg/pin.svg?inline";
import vClickOutside from "click-outside-vue3";
export default defineComponent({
  name: "EditStydent",
  data() {
    return {
      dataSwimming: ["Swimming"],
      dataSchool: ["SRA"],
      studentData: {},
      imagePreview: null,
      isPreview: false,
      full_name: "",
      email: "",
      phone_number: null,
      errorInstructor: null,
      schoolsSchools: null,
      file: null,
      instructorId: null,
      instructorData: {},
      editTeg: false,
      studentId: null,
      searchData: "",
      searchDataBody: false,
      instructorName: [],
      levelsData: [],
      skillsData: [],
      skillId: "",
      defaultLevelName: "",
      defaultSkillName: "",
    };
  },
  components: {
    ArrowBack,
    Form,
    Field,
    Select,
    // ErrorMessage,
    AlertBlock,
    PhotoMock,
    Search,
    SelectArrow,
    PinSvg,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    this.studentId = this.$route.query.student;
    this.getStudent(this.studentId);
    this.getLevels();
    this.getInstructors();
  },
  computed: {
    reversedInstructorName() {
      if (this.instructorName) {
        return [...this.instructorName].reverse();
      } else return [];
    },
  },
  watch: {
    searchData() {
      this.getInstructors();
    },
  },
  methods: {
    isChecked(id) {
      if (this.instructorName) {
        return this.instructorName.some((selected) => selected.id === id);
      }
    },
    getStudent(id) {
      this.$store.dispatch("students/getStudent", id).then(
        (res) => {
          this.studentData = res.data;
          this.full_name = this.studentData.full_name;
          this.email = this.studentData.email;
          this.phone_number = this.studentData?.phone?.value;
          this.defaultLevelName = this.studentData?.level?.title;
          this.defaultSkillName = this.studentData?.skill?.skill_order;
          this.instructorName = this.studentData?.instructors;
          this.getSkills(this.studentData?.level?.id);
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    getInstructors(params) {
      params = "?search=" + this.searchData;
      this.$store.dispatch("instructors/getInstructors", params).then(
        (res) => {
          this.instructorData = res.data;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    getLevels() {
      this.$store.dispatch("general/getLevels").then(
        (res) => {
          this.levelsData = res.data;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    getSkills(id) {
      this.$store.dispatch("general/getSkillsByLevels", id).then(
        (res) => {
          this.skillsData = res.data;
        },
        (error) => {
          this.errorInstructor = error.response.data.errors;
        }
      );
    },
    setInstructor(id, name) {
      if (this.instructorName !== null) {
        const index = this.instructorName.findIndex(
          (instructor) => instructor.full_name === name
        );
        if (index !== -1) {
          this.instructorName.splice(index, 1);
        } else {
          this.instructorName.push({
            full_name: name,
            id: id,
          });
        }
      } else {
        this.instructorName = [];
        this.instructorName.push({ full_name: name, id: id });
      }
    },
    deleteStident(id) {
      this.instructorName = this.instructorName.filter(
        (item) => item.id !== id
      );
    },
    editStudent() {
      const API_URL = process.env.VUE_APP_URL;
      const formData = new FormData();
      const sportIds = ["1"];
      const schoolIds = ["1"];
      const instructorIds = [];
      if (this.instructorName?.length > 0) {
        for (let i = 0; i < this.instructorName.length; i++) {
          instructorIds.push(this.instructorName[i].id);
        }
      }
      formData.append("full_name", this.full_name);
      formData.append("email", this.email);
      formData.append("phone", this.phone_number);
      if (this.skillId !== "") {
        formData.append("skill_id", this.skillId);
      }
      if (instructorIds.length > 0) {
        instructorIds.forEach((id) => {
          formData.append("instructor_ids[]", id);
        });
      }
      sportIds.forEach((id) => {
        formData.append("sport_ids[]", id);
      });
      schoolIds.forEach((id) => {
        formData.append("school_ids[]", id);
      });
      if (this.file !== null) {
        formData.append("image", this.file);
      }
      this.axios
        .post(
          `${API_URL}admin/schools/${JSON.parse(localStorage.getItem('admin-school-id'))}/students/${this.studentId}/update`,
          formData,
          { headers: authHeader() }
        )
        .then(
          () => {
            this.$router.push("/admin/students");
          },
          (error) => {
            this.errorInstructor = error.response.data.errors;
          }
        );
    },
    handleOptionSelectedLevel(selectedOption) {
      this.getSkills(selectedOption.id);
      this.defaultSkillName = "";
    },
    handleOptionSelectedSkill(selectedOption) {
      this.skillId = selectedOption.id;
    },
    handleOptionSelected(selectedOption) {
      console.log(selectedOption, "s");
    },
    editStydent() {
      this.$router.push("/admin/students");
    },
    openMultiSelect() {
      this.searchDataBody = !this.searchDataBody;
      // this.searchDataBody = true
    },
    closeMultiSelect() {
      this.searchDataBody = false;
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append("thumbnail", this.file);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.imagePreview = reader.result;
          this.isPreview = true;
          this.studentData.image = null;
        }.bind(this),
        false
      );
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
  },
});
